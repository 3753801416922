import React, { useState } from 'react'
import style from '../../styling/Panels.module.css'
import { Redirect } from 'react-router-dom'
import Cookies from 'universal-cookie'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"

const LOGIN = gql`
mutation loginUser($email: String!, $password: String!) {
	loginUser(email: $email, password: $password) {
		token
	}
}
`

function ErrorToast(message) {
	toast.error(message)
}

function Component( props ) {
	const cookies = new Cookies()
	const [login] = useMutation(LOGIN, {
		onError(e) {
			ErrorToast("Could not login")
			console.log(e)
		},
		onCompleted(r) {
			var setCookies = new Promise(function setCookies(resolve, reject) {
				cookies.set('x-token', r.loginUser.token)
				var base64Url = r.loginUser.token.split('.')[1]
				var base64 = base64Url.replace('-', '+').replace('_', '/')
				cookies.set('x-role', JSON.parse(atob(base64)).role)
				cookies.set('new-login', true)
				cookies.set('login-state', true)
				resolve(true)
			})

			setCookies.then(
				function(result) {
					setLoginSuccess(true)
				}
			)
		}
	})

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const {loginSuccess, setLoginSuccess} = props

	const handleSubmit = e => {
		e.preventDefault()
		cookies.remove('x-token')
		cookies.remove('x-role')
		login({ 
			variables: {
				email: username, 
				password: password
			}
		})
	}
    
	return (
		<div style={{margin:'40px auto auto', textAlign: 'center'}}>
			{ loginSuccess 
				? <Redirect to={{ pathname: "/nbots" }} />
				: null 
			}
			<div className={ style.panel_row } style={{display:'inline-block'}}>	
				<div className={ style.panel } >
					<form onSubmit={ handleSubmit } style={{padding: '15px 15px 25px'}}>
						<h3 style={{fontSize:'25px'}}>Habitat Learn</h3>
						<input type='text' id='login-username' placeholder='Username' style={{fontSize: '18px', width:'80%', marginBottom: '10px'}} value={ username } onChange={ e => setUsername(e.target.value) } required />
						<input type='password' id='login-password' placeholder='Password' style={{fontSize: '18px', width:'80%'}} value={ password } onChange={ e => setPassword(e.target.value) } required />
						<div style={{marginTop: '20px'}}>
							<button style={{fontSize: '20px', fontWeight: '600'}}>Login</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default Component