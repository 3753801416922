import React from 'react';
import "react-toastify/dist/ReactToastify.css";
import AssignNBotCampus from './AssignNbotCampus';

export default function AssignNBot(props) {
	
		return (
			<>
				<AssignNBotCampus 
					nBot_id={props.id} 
					classAssignment={props.classAssignment} 
					hardware_number={ props.hardware_number}
				/>
			</>
		);
}