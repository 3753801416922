import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import style from '../../../styling/Panels.module.css';
import Modal from 'react-modal';
import mqtt from 'mqtt'

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

Modal.setAppElement('#root')

const PUT_NBOT = gql`
    mutation put_nbot($id: ID!, $classroom_id: ID, $password: String!, $hardware_number: String!, $url: String!, $status: Int!) {
        updateNBot(
            id: $id,
            classroom_id: $classroom_id,
            password: $password, 
            hardware_number: $hardware_number,
            url: $url,
            status: $status,
        ) {
            id
        }
    }
`

const POST_NBOT = gql`
    mutation post_nbot($password: String, $hardware_number: String!, $url: String, $status: Int) {
        createNBot(
            password: $password, 
            hardware_number: $hardware_number,
            url: $url,
            status: $status,
        ) {
            id
        }
    }
`

function Toast(message) {
	toast.success(message);
}

const customStyles = {
	content : {
	    top                   : '30%',
	    left                  : '50%',
	    right                 : 'auto',
	    bottom                : 'auto',
	    marginRight           : '-50%',
	    transform             : 'translate(-50%, -50%)',
	    border				  : '1px solid #878787',
		padding				  : 0,
		boxShadow             : '0px 8px 15px rgba(181, 181, 181, 0.8)',
	}
};

export default function EditStudentModal (props) {
	const [ modalIsOpen, setIsOpen ] = React.useState(false);
    
	function openModal() {
	  setIsOpen(true)
	}

	function closeModal () {
		setIsOpen(false)
	}

	function clearFields() {
		set_password()
		set_hardware_number()
		set_url()
	}

	const [ password, set_password] = useState(props.nbot ? props.nbot.password : '')
	const [ hardware_number, set_hardware_number] = useState(props.nbot ? props.nbot.hardware_number : '')
	const [ url, set_url] = useState(props.nbot ? props.nbot.url : '')

	const [ redirect, setRedirect ] = React.useState(false)

	const [put_nbot] = useMutation(PUT_NBOT)
	const [post_nbot] = useMutation(POST_NBOT)

	const sendPublish = () => {
		console.log("Button clicked")
		var client  = mqtt.connect('ws://18.218.102.204:9001')

        var message = {
            type: "reboot_all",
        }
    
        client.on('connect', function () {
			client.publish('command/devices/inspection/', JSON.stringify(message))
			Toast("Successfully rebooting all Cameras")
        })
	}

	const handleSubmit = e => {
		e.preventDefault()
		if (props.usage === 'Create') {
			post_nbot({ 
				variables: {
					password: password,
					hardware_number: hardware_number,
					url: url,
					status: -1
				}
			})
			Toast("Camera Created")
			closeModal()
			clearFields()
		}else {
			if (props.nbot.classroom) {
				put_nbot({ 
					variables: {
						id: props.nbot.id,
						classroom_id: props.nbot.classroom.id,
						password: password,
						hardware_number: hardware_number,
						url: url,
						status: props.nbot.status,
					}
				})
			}
			else {
				put_nbot({ 
					variables: {
						id: props.nbot.id,
						password: password,
						hardware_number: hardware_number,
						url: url,
						status: props.nbot.status,
					}
				});
			}


			Toast("Camera Edited");
			return setRedirect(true);
		}
	}

	return (
		<>
			{ 
				(props.usage === 'Create') ? 
					(<button onClick={ openModal } className={ style.standalone_button }>{ props.usage }</button>) :
					((props.usage === 'RebootAll') ?
					(<button onClick={ openModal } onClick={sendPublish} className={ style.standalone_button }>Reboot all Launch Cameras</button>): 
					(<button onClick={ openModal } className={ style.td_button + ' ' + style.submit_text }>{ props.usage }</button>)) 
			}
			{ 
				(props.usage === 'RebootAll') ?
					'':
					(<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel="Update Camera"
						style={ customStyles }
					>
						<div className={ style.panel_row } style={{ marginBottom: 0 }}>
							<div className={ style.panel + ' ' + style.panel_modal }>
								<h3>{ props.usage } nBot</h3>
								<form onSubmit={ handleSubmit }>
									<table>
										<thead>
											<tr>
												<th>Password</th>
												<td><input type="text" value={ password } onChange={ e => set_password(e.target.value) } /></td>
											</tr>
											<tr>
												<th>Hardware Number</th>
												<td><input type="text" value={ hardware_number } onChange={ e => set_hardware_number(e.target.value) } /></td>
											</tr>
											<tr>
												<th>URL</th>
												<td><input type="text" value={ url } onChange={ e => set_url(e.target.value) } /></td>
											</tr>
										</thead>
									</table>
									<div style={{ margin: 'auto', width: '50%'}}>
										<div style={{ display: 'flex'}}>
											<button className={ style.form_button } onClick={closeModal}>Cancel</button>
											<button className={ style.form_button } type="submit">{ props.usage }</button>
										</div>
									</div>
								</form>
							</div>
						</div>
						{ redirect ? <Redirect to={ "/nbots" } /> : null }
					</Modal>)
			}
		</>
	);
}