import React, { useState, useEffect, useRef } from 'react';
import style from '../../../styling/Panels.module.css';
import SectionHeader from '../../../components/sectionheader';
import "react-toastify/dist/ReactToastify.css";
import 'react-circular-progressbar/dist/styles.css'
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import useSpinner from './spinner/useSpinner'
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import ReactPlayer from 'react-player'
import axios from 'axios'

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import screenful from "screenfull";
import Controls from "./components/Controls";

import { Configs } from './../../../Configs'

const useStyles = makeStyles((theme) => ({
	playerWrapper: {
	  width: "100%",
  
	  position: "relative",
	  // "&:hover": {
	  //   "& $controlsWrapper": {
	  //     visibility: "visible",
	  //   },
	  // },
	},
  
	controlsWrapper: {
	  visibility: "hidden",
	  position: "absolute",
	  top: 0,
	  left: 0,
	  right: 0,
	  bottom: 0,
	  background: "rgba(0,0,0,0.4)",
	  display: "flex",
	  flexDirection: "column",
	  justifyContent: "space-between",
	},
	topControls: {
	  display: "flex",
	  justifyContent: "flex-end",
	  padding: theme.spacing(2),
	},
	middleControls: {
	  display: "flex",
	  alignItems: "center",
	  justifyContent: "center",
	},
	bottomWrapper: {
	  display: "flex",
	  flexDirection: "column",
  
	  // background: "rgba(0,0,0,0.6)",
	  // height: 60,
	  padding: theme.spacing(2),
	},
  
	bottomControls: {
	  display: "flex",
	  alignItems: "center",
	  justifyContent: "space-between",
	  // height:40,
	},
  
	button: {
	  margin: theme.spacing(1),
	},
	controlIcons: {
	  color: "#777",
  
	  fontSize: 50,
	  transform: "scale(0.9)",
	  "&:hover": {
		color: "#fff",
		transform: "scale(1)",
	  },
	},
  
	bottomIcons: {
	  color: "#999",
	  "&:hover": {
		color: "#fff",
	  },
	},
  
	volumeSlider: {
	  width: 100,
	},
  }));

const format = (seconds) => {
	if (isNaN(seconds)) {
	  return `00:00`;
	}
	const date = new Date(seconds * 1000);
	const hh = date.getUTCHours();
	const mm = date.getUTCMinutes();
	const ss = date.getUTCSeconds().toString().padStart(2, "0");
	if (hh) {
	  return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
	}
	return `${mm}:${ss}`;
  };

let count = 0;

const JOIN_ROOM = gql`
	mutation joinRoom($type: String!, $hardware_number: String!, $roomID: String!, $classAssignment: String!) {
		joinRoom(
			type: $type,
			hardware_number: $hardware_number,
			roomID: $roomID,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_REBOOT = gql`
	mutation handleReboot($type: String!, $hardware_number: String!, $classAssignment: String!) {
		handleReboot(
			type: $type,
			hardware_number: $hardware_number,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_PLAY_AUDIO = gql`
	mutation handlePlayAudio($type: String!, $hardware_number: String!, $classAssignment: String!) {
		handlePlayAudio(
			type: $type,
			hardware_number: $hardware_number,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_STOP_AUDIO = gql`
	mutation handleStopAudio($type: String!, $hardware_number: String!, $classAssignment: String!) {
		handleStopAudio(
			type: $type,
			hardware_number: $hardware_number,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_RESOLUTION = gql`
	mutation handleResolution($type: String!, $hardware_number: String!, $resolution: String!, $classAssignment: String!) {
		handleResolution(
			type: $type,
			hardware_number: $hardware_number,
			resolution: $resolution,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_MUTE_AUDIO = gql`
	mutation handleMuteAudio($type: String!, $hardware_number: String!, $classAssignment: String!) {
		handleMuteAudio(
			type: $type,
			hardware_number: $hardware_number,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_UNMUTE_AUDIO = gql`
	mutation handleUnMuteAudio($type: String!, $hardware_number: String!, $classAssignment: String!) {
		handleUnMuteAudio(
			type: $type,
			hardware_number: $hardware_number,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_HIDE_VIDEO = gql`
	mutation handleHideVideo($type: String!, $hardware_number: String!, $classAssignment: String!) {
		handleHideVideo(
			type: $type,
			hardware_number: $hardware_number,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_SHOW_VIDEO = gql`
	mutation handleShowVideo($type: String!, $hardware_number: String!, $classAssignment: String!) {
		handleShowVideo(
			type: $type,
			hardware_number: $hardware_number,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_SHUTDOWN = gql`
	mutation handleShutdown($type: String!, $hardware_number: String!, $classAssignment: String!) {
		handleShutdown(
			type: $type,
			hardware_number: $hardware_number,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_LEAVE_ROOM = gql`
	mutation handleLeaveRoom($type: String!, $hardware_number: String!, $classAssignment: String!) {
		handleLeaveRoom(
			type: $type,
			hardware_number: $hardware_number,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_LEAVE_ROOM_EFFECT = gql`
	mutation handleLeaveRoomEffect($type: String!, $hardware_number: String!, $classAssignment: String!) {
		handleLeaveRoomEffect(
			type: $type,
			hardware_number: $hardware_number,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_INITIATE_IVS_STREAM = gql`
	mutation initiateIVSStream($type: String!, $hardware_number: String!, $classAssignment: String!) {
		initiateIVSStream(
			type: $type,
			hardware_number: $hardware_number,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_ROTATE_SCREEN_NBOT = gql`
	mutation rotateScreenNbot($type: String!, $hardware_number: String!, $classAssignment: String!) {
		rotateScreenNbot(
			type: $type,
			hardware_number: $hardware_number,
			classAssignment: $classAssignment
		) {
			id
		}
	}
`;

const HANDLE_QoS = gql`
    query handleQoS($nbot_id: ID!){
        handleQoS(id: $nbot_id){
            id
        }
    }
`;

const customStyles = {
	content : {
	    top                   : '30%',
	    left                  : '50%',
	    right                 : 'auto',
	    bottom                : 'auto',
	    marginRight           : '-50%',
	    transform             : 'translate(-50%, -50%)',
	    border				  : '1px solid #878787',
		padding				  : 0,
		boxShadow             : '0px 8px 15px rgba(181, 181, 181, 0.8)',
		paddingTop			  : '0%'	
	},
	button: {
		marginLeft: '10px', 
		padding: '3.5px 3.5px', 
		width: 'auto', 
		border: 'solid 1px black', 
		borderRadius: '4px',
		backgroundColor: '#E0E0E0'
	}
};

function Toast(message) {
	toast.success(message);
}

function ToastWarning(message) {
	toast.warn(message);
}

function ToastStatus(message, time=4000) {
	toast.success(message, {autoClose: time});
}

function ToastError(message) {
	toast.error(message);
}

export default function ControlsNbot ({id, classAssignment, hardware_number}) {

	const classes = useStyles();
  	const [showControls, setShowControls] = useState(false);
  	// const [count, setCount] = useState(0);
  	// const [anchorEl, setAnchorEl] = React.useState(null);
  	const [timeDisplayFormat, setTimeDisplayFormat] = React.useState("normal");
  	const [bookmarks, setBookmarks] = useState([]);
  	const [state, setState] = useState({
		pip: false,
		playing: true,
		controls: false,
		light: false,

		muted: true,
		played: 0,
		duration: 0,
		playbackRate: 1.0,
		volume: 1,
		loop: false,
		seeking: false,
  	});

	const playerRef = useRef(null);
	const playerContainerRef = useRef(null);
	const controlsRef = useRef(null);
	const canvasRef = useRef(null);
	const {
		playing,
		controls,
		light,

		muted,
		loop,
		playbackRate,
		pip,
		played,
		seeking,
		volume,
	} = state;

	const handlePlayPause = () => {
		setState({ ...state, playing: !state.playing });
	};
	
	const handleRewind = () => {
		playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
	};

	const handleFastForward = () => {
		playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
	};

	const handleProgress = (changeState) => {
		if (count > 3) {
		  controlsRef.current.style.visibility = "hidden";
		  count = 0;
		}
		if (controlsRef.current.style.visibility == "visible") {
		  count += 1;
		}
		if (!state.seeking) {
		  setState({ ...state, ...changeState });
		}
	};

	const handleSeekChange = (e, newValue) => {
		console.log({ newValue });
		setState({ ...state, played: parseFloat(newValue / 100) });
	};

	const handleSeekMouseDown = (e) => {
		setState({ ...state, seeking: true });
	};

	const handleSeekMouseUp = (e, newValue) => {
		console.log({ value: e.target });
		setState({ ...state, seeking: false });
		// console.log(sliderRef.current.value)
		playerRef.current.seekTo(newValue / 100, "fraction");
	};

	const handleDuration = (duration) => {
		setState({ ...state, duration });
	};

	const handleVolumeSeekDown = (e, newValue) => {
		setState({ ...state, seeking: false, volume: parseFloat(newValue / 100) });
	};

	const handleVolumeChange = (e, newValue) => {
		// console.log(newValue);
		setState({
		  ...state,
		  volume: parseFloat(newValue / 100),
		  muted: newValue === 0 ? true : false,
		});
	};

	const toggleFullScreen = () => {
		screenful.toggle(playerContainerRef.current);
	};

	const handleMouseMove = () => {
		console.log("mousemove");
		controlsRef.current.style.visibility = "visible";
		count = 0;
	};

	const hanldeMouseLeave = () => {
		controlsRef.current.style.visibility = "hidden";
		count = 0;
	};

	const handleDisplayFormat = () => {
		setTimeDisplayFormat(
		  timeDisplayFormat == "normal" ? "remaining" : "normal"
		);
	};

	const handlePlaybackRate = (rate) => {
		setState({ ...state, playbackRate: rate });
	};

	const hanldeMute = () => {
		setState({ ...state, muted: !state.muted });
	};

	const addBookmark = () => {
		const canvas = canvasRef.current;
		canvas.width = 160;
		canvas.height = 90;
		const ctx = canvas.getContext("2d");
	
		ctx.drawImage(
		  playerRef.current.getInternalPlayer(),
		  0,
		  0,
		  canvas.width,
		  canvas.height
		);
		const dataUri = canvas.toDataURL();
		canvas.width = 0;
		canvas.height = 0;
		const bookmarksCopy = [...bookmarks];
		bookmarksCopy.push({
		  time: playerRef.current.getCurrentTime(),
		  display: format(playerRef.current.getCurrentTime()),
		  image: dataUri,
		});
		setBookmarks(bookmarksCopy);
	};
	
	const currentTime =
		playerRef && playerRef.current
			? playerRef.current.getCurrentTime()
			: "00:00";
	
	const duration =
		playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00";
	const elapsedTime =
		timeDisplayFormat == "normal"
			? format(currentTime)
			: `-${format(duration - currentTime)}`;
	
	  const totalDuration = format(duration);

	// const resolutions = ['Low ~ 320x240', 'Medium ~ 640x480', 'High ~ 1280x720', 'Very High ~ 1920x1080', 'Ultra HD ~ 3840x2560']
	const [ modalIsOpen, setIsOpen ] = React.useState(false);

	const [ roomID, set_roomID] = useState('test_room')

	const [ joinedRoom, set_joinedRoom ] = useState(false)
	const [ resolution, setResolution] = useState('High');
	const [ audio, setAudio ] = useState(false)
	const [ video, setVideo ] = useState(false)
	const [joinClicked, setJoinClicked] = useState(false)
	const [working, setWorking] = useState(false)
	const [ startStream, setStartStream ] = useState(false)
	const [ shutdownClicked, setShutdownClicked] = useState(false)
	const [ rebootClicked, setRebootClicked] = useState(false)

	const { data, loading, error } = useQuery(
        HANDLE_QoS, 
        { 
			variables: { nbot_id: id },
			pollInterval: 1000 * 5
		})
	
	const [join_room] = useMutation(JOIN_ROOM, {
		onCompleted: async (r) =>{
			setJoinClicked(true)
		}
	})

	const [handle_reboot] = useMutation(HANDLE_REBOOT, {
		onCompleted: (r) =>{
			setRebootClicked(true)
		}
	})

	const [handle_play_audio] = useMutation(HANDLE_PLAY_AUDIO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Play Audio enabled on Launch Camera")
		}
	})

	const [handle_stop_audio] = useMutation(HANDLE_STOP_AUDIO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Stop Audio enabled on Launch Camera")
		}
	})

	const [handle_resolution] = useMutation(HANDLE_RESOLUTION, {
		onCompleted: (r) =>{
			setTimeout(() => hideSpinner(), 4500)
			if (resolution == "Low ~ 320x240"){
            	Toast("Setting resolution to Low ~ 320x240")
            }
            if (resolution == "Medium ~ 640x480"){
            	Toast("Setting resolution to Medium ~ 640x480")
            }
            if (resolution == "High ~ 1280x720"){
            	Toast("Setting resolution to High ~ 1280x720")
            }
            if (resolution == "Very High ~ 1920x1080"){
            	Toast("Setting resolution to Very High ~ 1920x1080")
            }
            if (resolution == "Ultra HD ~ 3840x2560"){
            	Toast("Setting resolution to Ultra HD ~ 3840x2560")
            }
		}
	})

	const [handle_mute_audio] = useMutation(HANDLE_MUTE_AUDIO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Mute Audio enabled on Launch Camera")
		}
	})

	const [handle_unmute_audio] = useMutation(HANDLE_UNMUTE_AUDIO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Unmute Audio enabled on Launch Camera")
		}
	})

	const [handle_hide_video] = useMutation(HANDLE_HIDE_VIDEO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Hide video enabled on Launch Camera")
		}
	})

	const [handle_show_video] = useMutation(HANDLE_SHOW_VIDEO, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Show video enabled on Launch Camera")
		}
	})

	const [handle_shutdown] = useMutation(HANDLE_SHUTDOWN, {
		onCompleted: (r) =>{
			setShutdownClicked(true)
		},
		onError(e) {
			ToastError("Error occured while shutting down camera: ", e)
		},
	})

	const [handle_leave_room] = useMutation(HANDLE_LEAVE_ROOM, {
		onCompleted: (r) =>{
			hideSpinner()
			Toast("Successfully Left Room")
		}
	})

	const [handle_leave_room_effect] = useMutation(HANDLE_LEAVE_ROOM_EFFECT)

	const [handle_initiate_ivs_stream] = useMutation(HANDLE_INITIATE_IVS_STREAM, {
		onCompleted: async (r) =>{
			setStartStream(true)
		},
		onError(e) {
			ToastError("Error occured while connecting to IVS stream: ", e)
		},
	})

	const [handle_rotate_screen_nbot] = useMutation(HANDLE_ROTATE_SCREEN_NBOT)

	const [spinner, showSpinner, hideSpinner] = useSpinner();

	const [url, setUrl] = useState('')

	const [stream, setStream] =  useState(false)

	// if(joinClicked){
	// 	if(data.handleQoS){
	// 		hideSpinner()
	// 		set_joinedRoom(true)
	// 		setJoinClicked(false)
	// 		setWorking(true)
	// 		ToastStatus("Launch Camera Joined to Classroom")
	// 	}
	// }

	if(startStream){
		console.log("Here")
		if(data.handleQoS){
			hideSpinner()
			setStartStream(false)
			ToastStatus("Successfully started Stream, please await for camera to buffer", 18000)
		}
	}

	if(shutdownClicked){
		if(data.handleQoS){
			hideSpinner()
			setShutdownClicked(false)
			ToastStatus("Successfully shutting down Launch Camera")
		}
	}

	if(rebootClicked){
		if(data.handleQoS){
			hideSpinner()
			setRebootClicked(false)
			ToastStatus("Successfully rebooting Launch Camera")
		}
	}

	const [correct, setCorrect] = useState(false)

	const initiateStream = async () => {
		await handle_initiate_ivs_stream({
			variables: {
				"type": "stream",
				"hardware_number": hardware_number,
				"classAssignment": JSON.stringify(classAssignment)
			}
		})

		showSpinner()


		setTimeout(async function() {
			hideSpinner()
			
			var data = JSON.stringify({
				query: `query getIvsUrlsByCameraId($id: ID!) {
						getIvsUrlsByCameraId(id: $id) {
							url
						}
					}`,
					variables: {"id":id}
				});
	
			var config = {
				method: 'post',
				url: Configs.url,
				headers: { 
					'Content-Type': 'application/json'
				},
				data : data
			};
	
			axios(config)
				.then(function (response) {
					console.log("Response", response)
					setUrl(response.data.data.getIvsUrlsByCameraId)
				})
				 .catch(function (error) {
					console.log(error);
				 });


			setStream(true)
		}, 18000)
	}

	async function openModal(hardware_number, classAssignment, url) {

		// await handle_initiate_ivs_stream({
		// 	variables: {
		// 		"type": "stream",
		// 		"hardware_number": hardware_number,
		// 		"classAssignment": JSON.stringify(classAssignment)
		// 	}
		// })

		var data = JSON.stringify({
			query: `query getIvsUrlsByCameraId($id: ID!) {
					getIvsUrlsByCameraId(id: $id) {
						url
					}
				}`,
				variables: {"id":id}
			});

		var config = {
			method: 'post',
			url: Configs.url,
			headers: { 
				'Content-Type': 'application/json'
			},
			data : data
		};

		axios(config)
			.then(function (response) {
				console.log("Response", response)
				setUrl(response.data.data.getIvsUrlsByCameraId)
			})
		 	.catch(function (error) {
				console.log(error);
		 	});


		setIsOpen(true)

		// axios({
		// 	method: 'get',
		// 	url: url.url,
		// 	headers: { }
		// })
		//   .then(async function (response) {
		// 	setIsOpen(true)
		//   })
		//   .catch(function (error) {
		// 	console.error("In error")
		// 	getUrl()
		// 	// openModal(hardware_number, classAssignment, url)
		// 	console.log(error);
		//   });
	}
  
	function closeModal () {
		setIsOpen(false);
	}

	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}

	const handleSubmit = async e => {
		if (roomID === ''){
			alert('Room ID cannot be empty', {autoClose: 8000})
			return false
		}
		e.preventDefault();

		if (classAssignment === null){
			classAssignment = 'null'
			showSpinner()
			join_room({
				variables: {
					"type": "stream",
					"hardware_number": hardware_number,
					"roomID": roomID,
					"classAssignment": classAssignment
				}
			})
			openInNewTab(`https://room.launch.camera/${roomID}`)		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				join_room({
					variables: {
						"type": "stream",
						"hardware_number": hardware_number,
						"roomID": roomID,
						"classAssignment": classAssignment
					}
				})
				openInNewTab(`https://room.launch.camera/${roomID}`)
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
			
		}
		closeModal()
	}

	const handleReboot = e => {
		e.preventDefault();

		if (classAssignment === null){
			classAssignment = 'null'
			showSpinner()
			handle_reboot({
				variables: {
					"type": "reboot",
					"hardware_number": hardware_number,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)
			if(classAssignmentCampus !== null){
				showSpinner()
				handle_reboot({
					variables: {
						"type": "reboot",
						"hardware_number": hardware_number,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
			
		}

		set_joinedRoom(false)
	}

	const handlePlayAudio = e =>{
		e.preventDefault();

		if (classAssignment === null){
			classAssignment = 'null'
			showSpinner()
			handle_play_audio({
				variables: {
					"type": "audio_test",
					"hardware_number": hardware_number,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_play_audio({
					variables: {
						"type": "audio_test",
						"hardware_number": hardware_number,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
			
		}
	}

	const handleStopAudio = e => {
		e.preventDefault();

		if (classAssignment === null){
			showSpinner()
			classAssignment = 'null'
			handle_stop_audio({
				variables: {
					"type": "audio_test",
					"hardware_number": hardware_number,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_stop_audio({
					variables: {
						"type": "audio_test",
						"hardware_number": hardware_number,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
			
		}
	}

	const handleResolution = e => {
		e.preventDefault();

		if (classAssignment === null){
			showSpinner()
			classAssignment = 'null'
			handle_resolution({
				variables: {
					"type": "resolution",
					"hardware_number": hardware_number,
					"resolution": resolution,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_resolution({
					variables: {
						"type": "resolution",
						"hardware_number": hardware_number,
						"resolution": resolution,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
		}
	}

	const handleMuteAudio = e => {
		e.preventDefault();

		if (classAssignment === null){
			showSpinner()
			classAssignment = 'null'
			handle_mute_audio({
				variables: {
					"type": "audio_stream",
					"hardware_number": hardware_number,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_mute_audio({
					variables: {
						"type": "audio_stream",
						"hardware_number": hardware_number,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
		}

		setAudio(true)
	}

	const handleUnMuteAudio = e => {
		e.preventDefault();
		if (classAssignment === null){
			showSpinner()
			classAssignment = 'null'
			handle_unmute_audio({
				variables: {
					"type": "audio_stream",
					"hardware_number": hardware_number,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_unmute_audio({
					variables: {
						"type": "audio_stream",
						"hardware_number": hardware_number,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
		}
		setAudio(false)
	}

	const handleHideVideo = e => {
		e.preventDefault();
		if (classAssignment === null){
			showSpinner()
			classAssignment = 'null'
			handle_hide_video({
				variables: {
					"type": "video_stream",
					"hardware_number": hardware_number,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_hide_video({
					variables: {
						"type": "video_stream",
						"hardware_number": hardware_number,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
		}

		setVideo(true)
	}

	const handleShowVideo = e => {
		e.preventDefault();

		if (classAssignment === null){
			showSpinner()
			classAssignment = 'null'
			handle_show_video({
				variables: {
					"type": "video_stream",
					"hardware_number": hardware_number,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_show_video({
					variables: {
						"type": "video_stream",
						"hardware_number": hardware_number,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
		}
		setVideo(false)
	}

	const handleShutDown = e => {
		e.preventDefault();

		if (classAssignment === null){
			showSpinner()
			classAssignment = 'null'
			handle_shutdown({
				variables: {
					"type": "shutdown",
					"hardware_number": hardware_number,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_shutdown({
					variables: {
						"type": "shutdown",
						"hardware_number": hardware_number,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
		}

		setVideo(true)
		set_joinedRoom(false)
	}

	const handleLeaveRoom = e => {
		e.preventDefault();

		console.log(classAssignment)
		console.log(typeof(classAssignment))

		if (classAssignment === null){
			showSpinner()
			classAssignment = 'null'
			handle_leave_room({
				variables: {
					"type": "stream_off",
					"hardware_number": hardware_number,
					"classAssignment": classAssignment
				}
			})		
		}else{
			let classAssignmentName = classAssignment.name
			let classAssignmentCampus = classAssignment.campus
			classAssignment = JSON.stringify(classAssignment)

			if(classAssignmentCampus !== null){
				showSpinner()
				handle_leave_room({
					variables: {
						"type": "stream_off",
						"hardware_number": hardware_number,
						"classAssignment": classAssignment
					}
				})
			}else{
				ToastWarning("Classroom " + classAssignmentName + " is not assigned to a campus")
			}
		}
		set_joinedRoom(false)
	}

	const getUrl = () => {
		var data = JSON.stringify({
			query: `query getIvsUrlsByCameraId($id: ID!) {
					getIvsUrlsByCameraId(id: $id) {
						url
					}
				}`,
				variables: {"id":id}
			});

		var config = {
			method: 'post',
			url: Configs.url,
			headers: { 
				'Content-Type': 'application/json'
			},
			data : data
		};

		axios(config)
			.then(function (response) {
				console.log("Response", response)
				setUrl(response.data.data.getIvsUrlsByCameraId)
			})
		 	.catch(function (error) {
				console.log(error);
		 	});
	}

	const rotateVideo = async () => {
        await handle_rotate_screen_nbot({
            variables: {
                "type": "rotate",
                "hardware_number": hardware_number,
                "classAssignment": JSON.stringify(classAssignment)
            }
        })
    }

	useEffect(() => {

		var data = JSON.stringify({
			query: `query getIvsUrlsByCameraId($id: ID!) {
					getIvsUrlsByCameraId(id: $id) {
						url
					}
				}`,
				variables: {"id":id}
			});

		var config = {
			method: 'post',
			url: Configs.url,
			headers: { 
				'Content-Type': 'application/json'
			},
			data : data
		};

		axios(config)
			.then(function (response) {
				console.log("Response", response)
				setUrl(response.data.data.getIvsUrlsByCameraId)
			})
		 	.catch(function (error) {
				console.log(error);
		 	});

		// if (classAssignment === null){
		// 	showSpinner()
		// 	classAssignment = 'null'
		// 	handle_leave_room_effect({
		// 		variables: {
		// 			"type": "stream_off",
		// 			"hardware_number": hardware_number,
		// 			"classAssignment": classAssignment
		// 		}
		// 	})		
		// 	hideSpinner()
		// 	set_joinedRoom(false)

		// }else{
		// 	let classAssignmentCampus = classAssignment.campus
		// 	classAssignment = JSON.stringify(classAssignment)
		// 	if(classAssignmentCampus !== null){
		// 		showSpinner()
		// 		handle_leave_room_effect({
		// 			variables: {
		// 				"type": "stream_off",
		// 				"hardware_number": hardware_number,
		// 				"classAssignment": classAssignment
		// 			}
		// 		})
		// 		hideSpinner()
		// 	set_joinedRoom(false)
		// 	}else{}
		// }

		return () => {
			if (classAssignment === null){
				showSpinner()
				classAssignment = 'null'
				handle_leave_room_effect({
					variables: {
						"type": "stream_off",
						"hardware_number": hardware_number,
						"classAssignment": classAssignment
					}
				})		
			}else{
				let classAssignmentCampus = classAssignment.campus
				classAssignment = JSON.stringify(classAssignment)
				if(classAssignmentCampus !== null){
					showSpinner()
					handle_leave_room_effect({
						variables: {
							"type": "stream_off",
							"hardware_number": hardware_number,
							"classAssignment": classAssignment
						}
					})
				}else{}
			}
			set_joinedRoom(false)
		}
	}, [])

	return (
		<div className={ style.table_wrapper }>
			<SectionHeader title='Camera Controls' />
			<div className= {style.control_buttons}>
				{ (!joinedRoom && !spinner) ?
					stream ? <div><button onClick={ () => openModal(hardware_number, classAssignment, url) } style={ customStyles.button }>View Stream</button>
						<Modal
							isOpen={ modalIsOpen }
							onRequestClose={ closeModal }
							contentLabel="View Stream"
							style={ customStyles }
							style={{padding: "2%"}}
						>
							{/* <div className={ style.panel_row } style={{ marginBottom: 0 }}>
								<div className={ style.panel + ' ' + style.panel_modal }> */}
									{/* <form onSubmit={ handleSubmit }>
										<div style={{ margin: 'auto'}}>
											<h3>Join Room</h3>
											<table>
												<thead>
													<tr>
														<th>Room ID</th>
														<td><input type="text" value={ roomID } onChange={ e => set_roomID(e.target.value) } /></td>
													</tr>
												</thead>
											</table>
											<div style={{ margin: 'auto', width: '50%'}}>
												<div style={{ display: 'flex'}}>
													<button className={ style.form_button } onClick={closeModal}>Cancel</button>
													<button className={ style.form_button } type="submit">Join</button>
												</div>
											</div>
										</div>
									</form> */}
									{/* <div className={ style.panel + ' ' + style.panel_double }>
										<div className={ style.panel_row } style={{ marginBottom: 0 }}>
											<div className={ style.panel + ' ' + style.panel_modal_video }>
												<VideoPlayer src={ url } controls={ true } autoplay={ true } />
											</div>
										</div>
									</div> */}

									{/* { url ? <ReactPlayer controls url={url} playing={true}/> : <p>Video Stream not available</p> } */}
									{/* <ReactPlayer 
										controls url={url.url} 
										playing={true}
									/> */}
									<Container maxWidth="md">
										<div
										onMouseMove={handleMouseMove}
										onMouseLeave={hanldeMouseLeave}
										ref={playerContainerRef}
										className={classes.playerWrapper}
										>
										<ReactPlayer
												ref={playerRef}
												width="100%"
												height="100%"
												url={url.url}
												pip={pip}
												playing={playing}
												controls={false}
												light={light}
												loop={loop}
												playbackRate={playbackRate}
												volume={volume}
												muted={muted}
												onProgress={handleProgress}
												config={{
												file: {
													attributes: {
													crossorigin: "anonymous",
													},
												},
											}}
										/>

										<Controls
											ref={controlsRef}
											onSeek={handleSeekChange}
											onSeekMouseDown={handleSeekMouseDown}
											onSeekMouseUp={handleSeekMouseUp}
											onDuration={handleDuration}
											onRewind={handleRewind}
											onPlayPause={handlePlayPause}
											onFastForward={handleFastForward}
											playing={playing}
											played={played}
											elapsedTime={elapsedTime}
											totalDuration={totalDuration}
											onMute={hanldeMute}
											muted={muted}
											onVolumeChange={handleVolumeChange}
											onVolumeSeekDown={handleVolumeSeekDown}
											onChangeDispayFormat={handleDisplayFormat}
											playbackRate={playbackRate}
											onPlaybackRateChange={handlePlaybackRate}
											onToggleFullScreen={toggleFullScreen}
											volume={volume}
											onBookmark={addBookmark}
											hardware_number={hardware_number}
											rotateVideo={rotateVideo}
										/>
										</div>

										<Grid container style={{ marginTop: 20 }} spacing={3}>
										{bookmarks.map((bookmark, index) => (
											<Grid key={index} item>
											<Paper
												onClick={() => {
												playerRef.current.seekTo(bookmark.time);
												controlsRef.current.style.visibility = "visible";

												setTimeout(() => {
													controlsRef.current.style.visibility = "hidden";
												}, 1000);
												}}
												elevation={3}
											>
												<img crossOrigin="anonymous" src={bookmark.image} />
												<Typography variant="body2" align="center">
												bookmark at {bookmark.display}
												</Typography>
											</Paper>
											</Grid>
										))}
										</Grid>
										<canvas ref={canvasRef} />
									</Container>
								{/* </div>
							</div> */}
							{/* <div className={ style.panel + ' ' + style.panel_double }>
								<div className={ style.panel_row } style={{ marginBottom: 0 }}>
									<div className={ style.panel + ' ' + style.panel_modal_video }>
										<VideoPlayer src={ url } controls={ true } autoplay={ true } />
									</div>
								</div>
							</div> */}
							
						</Modal>
					</div>: 
					<div><button style={ customStyles.button } onClick={ () => initiateStream()}>Start Stream</button></div>
					: ''
				}
				{/* { joinedRoom ?
					(<div className= {style.control_buttons}>
						<button type="submit" value="Submit" onClick={handlePlayAudio} style={ customStyles.button }>Play Audio</button>
						<button type="submit" value="Submit" onClick={handleStopAudio} style={ customStyles.button }>Stop Audio</button>
						<form onSubmit={ handleResolution }>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<select id='assign-resolution' value={resolution} style={{ marginLeft: 0 }} onChange={ e => setResolution(e.target.value) }>
									<option value={ -1 }>{ "Resolution" }</option>
									{resolutions.map(resolution => (
										<option key={ resolution } value={ resolution }>{ resolution }</option>
							    ))}
								</select>
								<button type="submit" value="Submit" className={ style.td_button + ' ' + style.submit_text }>Set Resolution</button>
							</div>
						</form>
						{ !audio ? 
							(<button type="submit" value="Submit" onClick={handleMuteAudio} style={ customStyles.button }>Mute Audio</button>) : 
							(<button type="submit" value="Submit" onClick={handleUnMuteAudio} style={ customStyles.button }>Unmute Audio</button>) 
						}
						{ !video ? 
							(<button type="submit" value="Submit" onClick={handleHideVideo} style={ customStyles.button }>Hide Video</button>) : 
							(<button type="submit" value="Submit" onClick={handleShowVideo} style={ customStyles.button }>Show Video</button>) 
						}
						<button type="submit" value="Submit" onClick={handleLeaveRoom} style={ customStyles.button }>Leave Room</button>
						<button type="submit" value="Submit" onClick={handleReboot} style={ customStyles.button }>Reboot</button>
					  	<button type="submit" value="Submit" onClick={handleShutDown} style={ customStyles.button }>Shutdown</button>
					  </div>
					  ) : !spinner ? <p style={{border: "none", display: 'flex', paddingBottom: "10px"}}>Join Room to view camera controls</p> : '' } */}
				{!spinner && !joinedRoom? <button type="submit" value="Submit" onClick={handleReboot} style={ customStyles.button }>Reboot Camera</button>: '' }
				{!spinner && !joinedRoom? <button type="submit" value="Submit" onClick={handleShutDown} style={ customStyles.button }>Shutdown Camera</button>: '' }
			</div>
			<div style={{maxWidth: "100px", maxHeight: "100px", paddingLeft: "450px"}}>
				{ spinner }
			</div>
		</div>
            
	)
}

