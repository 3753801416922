import React from 'react'
import ReactDOM from 'react-dom'
import Cookies from 'js-cookie'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
// import { WebSocketLink } from 'apollo-link-ws'
import { HttpLink } from 'apollo-link-http'
import { ApolloProvider } from '@apollo/react-hooks'
import App from './App'
// import { split } from 'apollo-link'
// import { getMainDefinition } from 'apollo-utilities'

import { Configs } from './Configs';

import './styling/fonts/AvenirLTStd-Medium.otf'
import './styling/ChatWindow.css'

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
	dsn: "https://8a2eaae3767a4d1f833cc46243013d3c@o1032448.ingest.sentry.io/5999420",
	integrations: [new Integrations.BrowserTracing()],
  
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
  });

const httpLink = new HttpLink({
	uri: Configs.url,
	headers: {
		'client-name': 'nBot [web]',
		'client-version': '1.0.0',
		'x-token': Cookies.get('x-token') 
			? Cookies.get('x-token')
			: null
	},
})

/*
No longer using Apollo Websockets
const wsLink = new WebSocketLink({
	uri: `wss://nbot-graphql.herokuapp.com/graphql`,
	headers: {
		'client-name': 'nBot [web]',
		'client-version': '1.0.0',
		'x-token': Cookies.get('x-token') 
			? Cookies.get('x-token')
			: null
	},
	options: {
		reconnect: true
	}
})

const link = split(
	// split based on operation type
	({ query }) => {
	  const definition = getMainDefinition(query);
	  return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
	  );
	},
	wsLink,
	httpLink
)
*/

const cache = new InMemoryCache()
const client = new ApolloClient({
	cache,
	link: httpLink
})

ReactDOM.render(
	<div style={{ padding: 0, margin: 0, height: "100%" }}>
		<ApolloProvider client={client}>
			<App />
		</ApolloProvider>
	</div>,
	document.getElementById('root'),
);